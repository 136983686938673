var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('x-card',{staticClass:"o2a-task-list"},[(_vm.isTasksLoading)?_c('x-loader',{staticClass:"o2a-task-list__loader"}):[_c('heading',{staticClass:"o2a-task-list__heading",attrs:{"level":"3"}},[_vm._v(" История задач ")]),(_vm.taskList.length)?[_c('grid',{staticClass:"o2a-task-list__grid",attrs:{"auto":"","id-field-name":"pk","cells":_vm.taskList,"columns":_vm.columns},scopedSlots:_vm._u([{key:"pk",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.pk)+" ")]}},{key:"shops",fn:function(ref){
var row = ref.row;
return [(row.shop && row.shop_from)?[_c('x-popover',{attrs:{"trigger":"hover"},scopedSlots:_vm._u([{key:"popover",fn:function(){return [_c('p',{staticClass:"o2a-task-list__mp-2-mp-popup"},[_vm._v(" "+_vm._s(row.shop_from.name)+" > "+_vm._s(row.shop.name)+" ")])]},proxy:true}],null,true)},[_c('Mp2Mp',{attrs:{"from":row.shop_from,"to":row.shop,"without-name":true}})],1)]:_vm._e()]}},{key:"createdAt",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.formatDate(row.created_at))+" "),_c('br'),_c('span',{staticClass:"o2a-task-list__time"},[_vm._v(_vm._s(_vm.formatTime(row.created_at)))])]}},{key:"finishedAt",fn:function(ref){
var row = ref.row;
return [(row.finished_at)?[_vm._v(" "+_vm._s(_vm.formatDate(row.finished_at))+" "),_c('br'),_c('span',{staticClass:"o2a-task-list__time"},[_vm._v(_vm._s(_vm.formatTime(row.finished_at)))])]:_vm._e()]}},{key:"status",fn:function(ref){
var row = ref.row;
return [_c('task-status',{attrs:{"task":row}})]}},{key:"actions",fn:function(ref){
var row = ref.row;
return [(row.status === 'draft')?[_c('x-btn',{attrs:{"outline":"","prepend":"play","metrics":"mp2mp_tasks_continue","metrics-payload":_vm.bindMetricsPayload(row)},on:{"click":function($event){return _vm.goToTask(row)}}},[_vm._v(" Продолжить ")])]:[_c('x-btn',{attrs:{"outline":"","metrics":"mp2mp_tasks_show","metrics-payload":_vm.bindMetricsPayload(row)},on:{"click":function($event){return _vm.goToTask(row, 4)}}},[_vm._v(" Посмотреть ")])]]}}],null,false,3258107657)}),_c('x-paginator',{attrs:{"options":_vm.newPaginationMixin_pagination},on:{"input":_vm.newPaginationMixin_onUpdate}})]:_c('x-placeholder',{staticClass:"o2a-task-list__placeholder",attrs:{"icon":"no-data","desc":"Ни одной задачи не было запущено"},scopedSlots:_vm._u([{key:"bottom",fn:function(){return [_c('x-btn',{attrs:{"metrics":"mp2mp_newexport"},on:{"click":_vm.goToNewTask}},[_vm._v(" Запустить задачу ")])]},proxy:true}])})]],2)}
var staticRenderFns = []

export { render, staticRenderFns }