<template>
  <x-card class="o2a-shops">
    <div class="o2a-shops__header">
      <heading level="3">
        Доступные магазины для экспорта
      </heading>
      <x-btn
        :disabled="!hasShops"
        metrics="mp2mp_newexport"
        @click="goToNewTask"
      >
        Запустить задачу
      </x-btn>
    </div>
    <template v-if="isShopsLoading">
      <x-loader />
    </template>
    <template v-else>
      <template v-if="!hasShops">
        <alert-bar
          v-if="showAlertBar"
          class="o2a-shops__alert-bar"
          type="info"
        >
          Для запуска задачи на экспорт товаров вам нужно добавить хотя бы один магазин AliExpress, и хотя бы один магазин Ozon или Wildberries
        </alert-bar>
      </template>
      <div class="o2a-shops__list">
        <template v-for="marketplace in marketplaces">
          <ozon-2-ali-shop-list
            :key="marketplace.code"
            :disabled="marketplace.disabled"
            :marketplace="marketplace.code"
          />
        </template>
      </div>
    </template>
    <market-info @save-changes="saveShopChanges" />
    <connect-modal @success="onConnectModalSuccess" />
  </x-card>
</template>

<script>

import Heading from '@/components/Common/Heading'
import AlertBar from '@/components/Interface/AlertBar'
import Ozon2AliShopList from '@/components/Ozon2Ali/Tasks/Ozon2AliShopList'
import { mapActions, mapGetters, mapState } from 'vuex'
import marketplaceCodes from '@/constants/marketplace-codes'
import ConnectModal from '@/components/Ozon2Ali/Tasks/Ozon2AliConnectModal'
import MarketInfo from '@/components/Ozon2Ali/Tasks/Ozon2AliMarketInfo'
import ServiceTurnedOffModal from '@/components/Ozon2Ali/ServiceTurnedOffModal'

const marketplaces = [
  {
    code: marketplaceCodes.OZON
  },
  {
    code: marketplaceCodes.WILDBERRIES
  },
  {
    code: marketplaceCodes.YANDEX,
    disabled: true
  },
  {
    code: marketplaceCodes.ALI
  }
]

export default {
  name: 'Ozon2AliShops',
  components: {
    MarketInfo,
    ConnectModal,
    Ozon2AliShopList,
    AlertBar,
    Heading
  },

  data () {
    return {
      marketplaces
    }
  },

  computed: {
    ...mapState('ozon2ali', ['shops', 'isShopsLoading']),
    ...mapGetters('ozon2ali', ['aliShops', 'ozonShops', 'wbShops']),

    hasShops () {
      return !!this.shops.length
    },

    showAlertBar () {
      return !(this.aliShops.length && (this.ozonShops.length || this.wbShops.length))
    }
  },

  created () {
    this.fetchShops()
    this.getClientList()
  },

  methods: {
    ...mapActions('ozon2ali', ['fetchShops']),
    ...mapActions('settings/permissions', ['getClientList']),
    ...mapActions('marketplaces', ['updateShop']),

    onConnectModalSuccess () {
      this.$modal.hide('connect-modal')
      this.fetchShops()
      this.getClientList()
    },

    goToNewTask () {
      this.$dialog.show(ServiceTurnedOffModal)
      /* this.$router.push({
        name: 'mp-to-mp.export',
        params: { taskId: 'new' }
      }) */
    },

    // eslint-disable-next-line camelcase
    async saveShopChanges (data) {
      try {
        const res = await this.updateShop({
          id: data.pk,
          name: data.shop,
          is_active: data.is_active,
          credentials: data.credentials
        })
        if (res?.isSuccess) {
          this.$toast.success('Успешно сохранено')
        }
        this.fetchShops()
      } finally {
        this.$modal.hide('market-info')
      }
    }
  }

}
</script>

<style lang="stylus">
.o2a-shops
  margin-top 20px
  padding 24px

  &__header
    width 100%
    display flex
    justify-content space-between
    margin-bottom 12px

  &__list
    display flex

  &__alert-bar
    margin-bottom 20px
</style>
