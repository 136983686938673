import _throttle from 'lodash/throttle'

export default {
  data () {
    return {
      newPaginationMixin_currentPage: 1,
      newPaginationMixin_currentLimit: 10,
      newPaginationMixin_total: 0,
      newPaginationMixin_fetchDataMethod: null
    }
  },

  computed: {
    newPaginationMixin_pagination () {
      return {
        page: this.newPaginationMixin_currentPage,
        limit: this.newPaginationMixin_currentLimit,
        total: this.newPaginationMixin_total
      }
    },

    newPaginationMixin_params () {
      return {
        limit: this.newPaginationMixin_currentLimit,
        offset: this.newPaginationMixin_currentLimit * (this.newPaginationMixin_currentPage - 1)
      }
    }
  },

  watch: {
    $route: {
      async handler (value) {
        this.newPaginationMixin_currentPage = +value.query.page || 1

        this.newPaginationMixin_currentLimit = +value.query.limit || 10

        this.newPaginationMixin_total = await this.newPaginationMixin_fetchDataMethod(this.newPaginationMixin_params)
      },
      immediate: true
    }
  },

  methods: {
    async initPagination (value) {
      this.newPaginationMixin_currentPage = +value.query.page || 1

      this.newPaginationMixin_currentLimit = +value.query.limit || 10
    },

    newPaginationMixin_onUpdate (pagination) {
      const page = pagination.limit !== this.newPaginationMixin_currentLimit ? 1 : pagination.page
      const limit = pagination.limit

      const query = {
        ...this.$route.query
      }

      if (page === 1) {
        delete query.page
      } else {
        query.page = page
      }

      if (limit === 10) {
        delete query.limit
      } else {
        query.limit = limit
      }

      this.$router.push({
        ...this.$route,
        query
      })
    }
  },

  created () {
    if (typeof this.newPaginationMixin_fetchDataMethod !== 'function') {
      throw new Error('newPaginationMixin_fetchDataMethod must be Function')
    } else {
      this.newPaginationMixin_fetchDataMethod = _throttle(this.newPaginationMixin_fetchDataMethod, 300)
    }
  }
}
