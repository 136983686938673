<template>
  <div class="o2a-shop-list">
    <div class="o2a-shop-list__icon">
      <svg-icon :name="marketplaceIcon" />
    </div>
    <x-btn
      :disabled="disabled"
      outline
      :prepend="disabled ? undefined : 'plus'"
      class="o2a-shop-list__button"
      :metrics="metricsEvents.addShop"
      :metrics-payload="{ marketplace_id: marketplace }"
      @click="openConnectModal"
    >
      <template v-if="disabled">
        Скоро
      </template>
      <template v-else>
        Добавить магазин
      </template>
    </x-btn>
    <template v-if="!disabled">
      <div class="o2a-shop-list__list">
        <template v-for="shop in visibleShops">
          <x-btn
            :key="shop.id"
            color="gray"
            :prepend="shopIcon"
            class="o2a-shop-list__shop"
            @click="openShopEditModal(shop)"
          >
            {{ getShopNameText(shop) }}
          </x-btn>
        </template>
      </div>
      <template v-if="isShowMoreButtonVisible">
        <x-btn
          class="o2a-shop-list__show-more"
          size="xs"
          text
          :append="isMenuExpanded ? 'dropdown-up' : 'dropdown-down'"
          @click="isMenuExpanded = !isMenuExpanded"
        >
          <template v-if="isMenuExpanded">
            Скрыть
          </template>
          <template v-else>
            Показать все
          </template>
        </x-btn>
      </template>
    </template>
  </div>
</template>

<script>
import marketplaceCodes from '@/constants/marketplace-codes'
import { mapGetters } from 'vuex'

export default {
  name: 'Ozon2AliShopList',
  props: {
    marketplace: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isMenuExpanded: false
    }
  },

  computed: {
    ...mapGetters('ozon2ali', ['ozonShops', 'aliShops', 'wbShops']),

    metricsEvents () {
      return {
        addShop: this.$route.meta?.metricsEvents?.addShop?.add
      }
    },

    shops () {
      switch (this.marketplace) {
        case marketplaceCodes.OZON:
          return this.ozonShops
        case marketplaceCodes.ALI:
          return this.aliShops
        case marketplaceCodes.WILDBERRIES:
          return this.wbShops
        case marketplaceCodes.YANDEX:
          return []
        default:
          return []
      }
    },

    visibleShops () {
      if (this.isMenuExpanded) {
        return this.shops
      } else {
        return this.shops.slice(0, 2)
      }
    },

    isShowMoreButtonVisible () {
      return this.shops.length > 2
    },

    marketplaceIcon () {
      switch (this.marketplace) {
        case marketplaceCodes.OZON:
          return 'ozon--full'
        case marketplaceCodes.WILDBERRIES:
          return 'wildberries--full'
        case marketplaceCodes.ALI:
          return 'ali--full'
        case marketplaceCodes.YANDEX:
          return 'beru--full'
        default:
          return null
      }
    },

    shopIcon () {
      switch (this.marketplace) {
        case marketplaceCodes.OZON:
          return 'ozon'
        case marketplaceCodes.WILDBERRIES:
          return 'wildberries'
        case marketplaceCodes.ALI:
          return 'aliexpress'
        case marketplaceCodes.BERU:
          return 'beru'
        default:
          return null
      }
    }
  },

  methods: {
    openConnectModal () {
      this.$modal.show('connect-modal', { formName: `connect-form-${this.marketplace}` })
    },

    openShopEditModal (shop) {
      this.$modal.show('market-info', { shop })
    },

    getShopNameText (shop) {
      if (shop.marketplace_code === marketplaceCodes.ALI && !shop.has_valid_token) {
        return `${shop.name} (невалидный токен)`
      }

      return shop.name
    }
  }
}
</script>

<style lang="stylus">
.o2a-shop-list
  min-width: calc(100% / 4)
  max-width: calc(100% / 4)

  border-right: 1px dashed #CACAD3;
  padding-left: 32px

  &:first-child
    padding-left: 0

  &:last-child
    border-right: none

  &__icon
    width 120px
    height 32px
    margin-bottom 20px

    svg
      width 120px
      height 32px

  &__button
    margin-bottom 8px

  &__list
    display flex
    flex-direction column
    align-items flex-start
    overflow hidden

  &__shop.x-btn
    margin-left 0
    margin-bottom 8px

</style>
