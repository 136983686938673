<template>
  <default-description
    identity="o2a"
    expandable
    class="o2a-description"
  >
    <template #title>
      <heading class="mb-0">
        Экспорт товаров
      </heading>
    </template>
    <template #any>
      <div class="o2a-description__content">
        <x-card class="flex-grow">
          <heading level="3">
            Добро пожаловать в сервис MP2MP от компании {{ companyDetails.name }}
          </heading>
          <p class="mb-4">
            Перенесём ваши карточки товара в автоматическом режиме.
          </p>
          <div class="primary-lightest-2 px-5 py-4 rounded-1">
            <p class="font-weight-semibold">
              Доступные направления для переноса
            </p>
            <div class="o2a-description__export">
              <div class="o2a-description__export-item">
                <div class="o2a-description__export-icon">
                  <x-icon
                    name="ozon"
                    size="s"
                  />
                </div>
                <div class="o2a-description__export-title">
                  Ozon
                </div>
              </div>
              <div class="o2a-description__export-arrow">
                <svg-icon name="arrow-right-small" />
              </div>
              <div class="o2a-description__export-item">
                <div class="o2a-description__export-icon">
                  <x-icon
                    name="aliexpress"
                    size="s"
                  />
                </div>
                <div class="o2a-description__export-title">
                  AliExpress
                </div>
              </div>
            </div>
            <div class="o2a-description__export">
              <div class="o2a-description__export-item">
                <div class="o2a-description__export-icon">
                  <x-icon
                    name="wildberries"
                    size="s"
                  />
                </div>
                <div class="o2a-description__export-title">
                  Wildberries
                </div>
              </div>
              <div class="o2a-description__export-arrow">
                <svg-icon name="arrow-right-small" />
              </div>
              <div class="o2a-description__export-item">
                <div class="o2a-description__export-icon">
                  <x-icon
                    name="aliexpress"
                    size="s"
                  />
                </div>
                <div class="o2a-description__export-title">
                  AliExpress
                </div>
              </div>
            </div>
            <div class="o2a-description__export o2a-description__export--disabled">
              <div class="o2a-description__export-item">
                <div class="o2a-description__export-icon">
                  <x-icon
                    name="yandex-market"
                    size="s"
                  />
                </div>
                <div class="o2a-description__export-title">
                  Яндекс.Маркет (Скоро!)
                </div>
              </div>
            </div>
          </div>
        </x-card>
        <video-activator v-if="isInstructionVisible" />
      </div>
    </template>
  </default-description>
</template>

<script>
import Heading from '@/components/Common/Heading'
import DefaultDescription from '@/components/Interface/Description'
import VideoActivator from '@/components/Ozon2Ali/Tasks/VideoActivator'
import companyDetails from '@/plugins/companyDetails'
import currentTheme from '@/plugins/currentTheme'
import { getDefaultThemeName } from '@xway-team/theme-detector'

export default {
  name: 'Ozon2AliDescription',
  components: { Heading, DefaultDescription, VideoActivator },
  data: () => ({
    companyDetails
  }),
  computed: {
    isInstructionVisible () {
      return currentTheme.name === getDefaultThemeName()
    }
  }
}
</script>

<style lang="stylus">
.o2a-description
  margin-top 27px

  .x-btn--ghost .x-btn__content
    padding: 0

  &__content
    display flex
    gap 24px
    width 100%

  &__export
    display flex
    align-items center
    margin-top 20px

    &-item
      display flex
      align-items center
      gap 8px
      min-width 112px
      max-width 112px

    &-title
      font-weight: $font-weights.semibold

    &-icon
      min-width 20px
      max-width 20px
      height 20px

      svg
        max-width 20px
        max-height 20px

    &-arrow
      min-width 20px
      max-width 20px
      height 20px
      margin-right 51px

      svg
        max-width 20px
        max-height 20px

    &--disabled

      opacity 0.5

      .o2a-description__export-item
        min-width 100%
        max-width 100%
</style>
