<template>
  <x-card @click="openVideoModal">
    <h4 class="headline-4 mb-2">
      Видео-инструкция
    </h4>
    <p class="mb-3">
      Посмотрите видео — в нём мы показываем,<br>как пользоваться инструментом.
    </p>
    <div class="image-wrap">
      <div class="play-circle">
        <x-icon
          style="transform: translateX(2px)"
          size="s"
          class="white--text"
          name="play-filled"
        />
      </div>
      <img
        class="activator-image"
        width="312"
        height="176"
        src="@/assets/images/ozon2ali/instruction_activator.png"
        alt="activator"
      >
    </div>
  </x-card>
</template>

<script>
import VideoModal from '@/components/Ozon2Ali/Tasks/VideoModal'

export default {
  name: 'Ozon2AliVideo',
  methods: {
    openVideoModal () {
      this.$dialog.show(VideoModal)
    }
  }
}
</script>

<style lang="stylus" scoped>
.image-wrap
  position relative
  display flex
  align-items center
  justify-content center
  border 2px solid $colors.gray-lightest-1
  border-radius 4px
  width 312px
  height 176px
  overflow hidden
  opacity 0.8
  transition opacity .3s

  &:hover
    opacity 1

.play-circle
  position absolute
  display flex
  align-items center
  justify-content center
  width 46px
  height 46px
  border-radius 50%
  background rgba(44, 44, 55, 0.25)

  &:hover
    background rgba(44, 44, 55, 0.75)
</style>
