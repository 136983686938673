<template>
  <x-dialog
    size="m"
    no-actions
    style="width: auto"
  >
    <div class="content-wrap">
      <iframe
        class="video-iframe"
        :src="src"
        title="Видеоинструкция"
        frameBorder="0"
        allow="clipboard-write; autoplay"
        webkitAllowFullScreen
        mozallowfullscreen
        allowFullScreen
      />
      <img
        class="preloader-image"
        src="@/assets/images/ozon2ali/instruction_activator.png"
        alt="background"
      >
    </div>
  </x-dialog>
</template>
<script>
export default {
  name: 'VideoModal',
  data: () => ({
    src: ''
  }),
  mounted () {
    /* для плавной анимации открытия */
    setTimeout(() => {
      this.src = 'https://rutube.ru/play/embed/d685f2334fb00a0b8e8ded062f556d0c'
    }, 300)
  }
}
</script>

<style lang="stylus" scoped>
.content-wrap
  position relative

.preloader-image
  position absolute
  top 50%
  left 50%
  transform translate(-50%, -50%)
  z-index 0

.video-iframe
  position relative
  z-index 1

.preloader-image,
.video-iframe
  width 550px
  height 314px

  +media-up('l')
    width 800px
    height 457px

  +media-up('xl')
    width 1100px
    height 628px

  +media-up('xxl')
    width 1400px
    height 800px
</style>
