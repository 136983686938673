<template>
  <div class="description">
    <x-btn
      ghost
      class="description__title"
      @click="toggleDescription"
    >
      <back-button
        v-if="back"
        :to="back"
        class="description__back"
        icon="back"
        @click.native.stop
      />

      <slot name="title" />

      <template v-if="expandable">
        <svg-icon
          name="info-fill"
          class="description__info-icon"
        />

        <div class="description__opener">
          <span
            v-if="showDescription"
            class="description__opener-expand"
          >
            Свернуть
          </span>
          <span
            v-else
            class="description__opener-narrow"
          >
            Развернуть
          </span>

          <svg-icon
            class="description__opener-icon"
            :class="{ 'description__opener-icon_reverse': showDescription }"
            name="chevron-down"
          />
        </div>
      </template>
    </x-btn>

    <transition-expand>
      <ol
        v-if="showDescription"
        class="description__blocks"
      >
        <li
          v-for="block in blocks"
          :key="block.id"
          class="description__item"
        >
          <div class="description__thumbnail">
            <img
              :src="block.img"
              class="description__image"
            >
          </div>
          <div class="description__content">
            <h4 class="description__item-title">
              {{ block.title }}
            </h4>
            <p class="description__text">
              {{ block.text }}
            </p>
          </div>
        </li>

        <li
          v-if="$slots.default"
          class="description__item"
        >
          <slot />
        </li>

        <template v-if="$slots.any">
          <slot name="any" />
        </template>
      </ol>
    </transition-expand>
  </div>
</template>

<script>
import BackButton from '@/components/Interface/BackButton.vue'
import TransitionExpand from '@/components/Interface/TransitionExpand.vue'

export default {
  components: {
    BackButton,
    TransitionExpand
  },

  props: {
    blocks: {
      type: Array,
      default: () => []
    },
    identity: {
      type: String,
      default: ''
    },
    expandable: Boolean,
    back: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      showDescription: true
    }
  },

  created () {
    const flag = JSON.parse(localStorage.getItem(`seller_show_${this.identity}_description`))
    this.showDescription = Boolean(flag ?? 1)
  },

  methods: {
    toggleDescription () {
      if (!this.expandable) return
      localStorage.setItem(`seller_show_${this.identity}_description`, this.showDescription ? 0 : 1)
      this.showDescription = !this.showDescription
    }
  }
}
</script>

<style lang="stylus" scoped>
.description
  &__back
    margin-left 10px

  &__title
    display flex
    align-items center
    margin-bottom 20px

    &:hover
      .description__opener-expand,
      .description__opener-narrow
        font-size 14px
        transform translateX(0)
        opacity 1

      .description__info-icon
        color #7b7b85

      .description__opener-icon
        stroke #7b7b85

  &__blocks
    display flex
    justify-content space-between
    margin 0
    padding 0
    list-style none

    @media (max-width 768px)
      overflow-x auto

      &::-webkit-scrollbar
        display none

  &__item
    flex-basis 460px
    overflow hidden
    margin-left 30px
    background-color #fff
    border-radius 8px
    box-shadow 0px 6px 16px 0px rgba(5, 2, 55, .04)

    @media (max-width 768px)
      overflow unset

  &__thumbnail
    width 100%
    background-color #fef6f4

  &__image
    display block
    margin 0 auto

  &__content
    padding 20px

  &__item-title
    margin-bottom 4px
    font-size 16px
    color #3c3c47

  &__text
    font-size 14px
    color #7b7b85

  &__info-icon
    width 16px
    height 16px
    margin-left 8px
    color #cacad3
    transition color .3s

  &__opener
    display flex
    align-items center

  &__opener-expand,
  &__opener-narrow
    margin-left 4px
    font-weight $font-weights.bold
    font-size 0
    color #7b7b85
    transition transform .5s, opacity 1s, font-size .3s
    opacity 0
    transform translateX(-50%)
    will-change transform, opacity, font-size

  &__opener-icon
    display block
    width 16px
    height 8px
    margin-top 2px
    transition transform .3s
    stroke #cacad3

    &_reverse
      transform rotate(-180deg)
</style>
