<template>
  <x-card class="o2a-task-list">
    <x-loader
      v-if="isTasksLoading"
      class="o2a-task-list__loader"
    />

    <template v-else>
      <heading
        level="3"
        class="o2a-task-list__heading"
      >
        История задач
      </heading>
      <template v-if="taskList.length">
        <grid
          auto
          id-field-name="pk"
          :cells="taskList"
          :columns="columns"
          class="o2a-task-list__grid"
        >
          <template #pk="{ row }">
            {{ row.pk }}
          </template>
          <template #shops="{ row }">
            <template v-if="row.shop && row.shop_from">
              <x-popover trigger="hover">
                <Mp2Mp
                  :from="row.shop_from"
                  :to="row.shop"
                  :without-name="true"
                />
                <template #popover>
                  <p class="o2a-task-list__mp-2-mp-popup">
                    {{ row.shop_from.name }} > {{ row.shop.name }}
                  </p>
                </template>
              </x-popover>
            </template>
          </template>
          <template #createdAt="{ row }">
            {{ formatDate(row.created_at) }} <br>
            <span class="o2a-task-list__time">{{ formatTime(row.created_at) }}</span>
          </template>
          <template #finishedAt="{ row }">
            <template v-if="row.finished_at">
              {{ formatDate(row.finished_at) }} <br>
              <span class="o2a-task-list__time">{{ formatTime(row.finished_at) }}</span>
            </template>
          </template>
          <template #status="{ row }">
            <task-status :task="row" />
          </template>
          <template #actions="{row}">
            <template v-if="row.status === 'draft'">
              <x-btn
                outline
                prepend="play"
                metrics="mp2mp_tasks_continue"
                :metrics-payload="bindMetricsPayload(row)"
                @click="goToTask(row)"
              >
                Продолжить
              </x-btn>
            </template>
            <template v-else>
              <x-btn
                outline
                metrics="mp2mp_tasks_show"
                :metrics-payload="bindMetricsPayload(row)"
                @click="goToTask(row, 4)"
              >
                Посмотреть
              </x-btn>
            </template>
          </template>
        </grid>

        <x-paginator
          :options="newPaginationMixin_pagination"
          @input="newPaginationMixin_onUpdate"
        />
      </template>

      <x-placeholder
        v-else
        icon="no-data"
        desc="Ни одной задачи не было запущено"
        class="o2a-task-list__placeholder"
      >
        <template #bottom>
          <x-btn
            metrics="mp2mp_newexport"
            @click="goToNewTask"
          >
            Запустить задачу
          </x-btn>
        </template>
      </x-placeholder>
    </template>
  </x-card>
</template>

<script>

import { mapActions, mapState } from 'vuex'
import NewPaginationMixin from '@/mixins/newPaginationMixin'
import Grid from '@/components/Interface/Grid.vue'
import Heading from '@/components/Common/Heading'
import dateFormat from '@/utils/date-formatter'
import { timeFormat } from '@/utils/formatters'
import Mp2Mp from '@/components/Ozon2Ali/Mp2Mp'
import TaskStatus from '@/components/Ozon2Ali/TaskStatus'

export default {
  name: 'Ozon2AliTaskList',
  mixins: [NewPaginationMixin],
  components: { TaskStatus, Mp2Mp, Heading, Grid },
  data () {
    return {
      columns: [
        {
          name: 'ID задания',
          type: 'pk'
        },
        {
          name: 'Магазины',
          type: 'shops'
        },
        {
          name: 'Дата запуска',
          type: 'createdAt'
        },
        {
          name: 'Дата завершения',
          type: 'finishedAt'
        },
        {
          name: 'Статус',
          type: 'status'
        },
        {
          name: 'Отмечено на экспорт',
          key: 'product_count'
        },
        {
          name: 'Экспортировано товаров',
          key: 'exported_count'
        },
        {
          name: 'Ошибок',
          key: 'error_count'
        },
        {
          name: '',
          type: 'actions',
          styles: {
            'text-align': 'right',
            width: '100%'
          }
        }
      ],
      newPaginationMixin_fetchDataMethod: this.fetchTasks
    }
  },
  computed: {
    ...mapState('ozon2ali', ['isTasksLoading', 'taskList'])
  },

  methods: {
    ...mapActions('ozon2ali', ['fetchTasks']),

    formatDate (date) {
      return dateFormat(date, 'DD.MM.YYYY', { utc: '' })
    },

    formatTime (date) {
      return timeFormat(date, 'HH:mm:ss', { utc: '' })
    },

    goToNewTask () {
      this.$router.push({
        name: 'mp-to-mp.export',
        params: { taskId: 'new' }
      })
    },

    goToTask (row, step) {
      this.$router.push({
        name: 'mp-to-mp.export',
        params: { taskId: row.pk, step }
      })
    },

    bindMetricsPayload (row) {
      return {
        shop_from: row.shop_from?.id,
        shop_to: row.shop?.id,
        marketplace_id: row.shop_from?.marketplace_code, // marketplace_id_from
        marketplace_id_to: row.shop?.marketplace_code
      }
    }
  }
}
</script>

<style lang="stylus">
.o2a-task-list
  margin-top 20px
  padding 24px
  min-height 368px
  display flex
  flex-direction column

  &__time
    color: $colors.gray-darker
    margin-top 4px
    display inline-block

  &__grid
    margin-bottom 20px
    border-top: 1px solid $colors.gray-light

    .grid__heading
      padding-top: 8px

  &__heading
    margin-bottom 20px

  &__placeholder
  &__loader
    margin auto

  &__mp-2-mp-popup {
    padding 10px 15px
  }
</style>
