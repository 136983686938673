<template>
  <div class="back">
    <x-btn
      v-if="to"
      v-bind="btnProps"
      class="back__btn"
    />
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    to: {
      type: Object,
      default: null
    }
  },

  computed: {
    tag () {
      return this.to ? 'x-btn' : 'div'
    },

    btnProps () {
      return {
        to: this.to,
        color: 'gray',
        prepend: 'chevron-left'
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
  .back
    display flex
    align-items center

    &__btn
      margin-right 20px

      >>> .x-icon
        margin-right 0
</style>
