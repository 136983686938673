<template>
  <modal
    :name="modalName"
    height="auto"
    @before-open="setShopInfo"
  >
    <div
      v-if="shop"
      class="market-info"
    >
      <header class="market-info__header">
        <h2 class="market-info__heading">
          Магазин
        </h2>
        <close-button @click="closeModal" />
      </header>

      <validation-observer v-slot="{ handleSubmit }">
        <default-form @submit="handleSubmit(onSubmit)">
          <dl
            class="market-info__property"
          >
            <dt class="market-info__property-name">
              Магазин
            </dt>
            <dd class="market-info__property-value">
              <x-input
                v-model="shop.shop"
              />
            </dd>
          </dl>

          <dl
            class="market-info__property"
          >
            <dt class="market-info__property-name">
              Маркетплейс
            </dt>
            <dd class="market-info__property-value">
              {{ marketplaceName[shop.marketplace_code] }}
            </dd>
          </dl>

          <dl
            v-for="field in editableCredentials"
            :key="field.name"
            class="market-info__property"
          >
            <dt class="market-info__property-name">
              {{ field.name }}
            </dt>
            <dd class="market-info__property-value">
              <x-form-item
                :name="field.name"
                rules="required"
              >
                <x-input
                  v-model="shop[field.key]"
                  :failed="isWildberriesTokenInvalid(field)"
                />
              </x-form-item>
            </dd>
          </dl>

          <div
            v-if="isAliexpressShop"
            class="market-info__property"
          >
            <span class="market-info__property-name">
              Авторизационный токен
            </span>
            <div class="market-info__token-status">
              <badge
                :status-list="tokenStatuses"
                :status="shop.has_valid_token ? 'actual' : 'wrong_token'"
              />

              <refresh-button
                v-if="!shop.has_valid_token && !isRefreshProcessed"
                ghost
                class="market-info__refresh-btn"
                @click="onRefreshToken"
              />
              <loader
                v-if="isRefreshProcessed"
                mini
              />
            </div>
          </div>

          <div class="market-info__status">
            <span class="market-info__property-name">
              Статус
            </span>
            <checkbox
              v-model="shop.is_active"
              rounded
              class="market-info__checkbox"
            >
              {{ shop.is_active ? 'Активен' : 'Не активен' }}
            </checkbox>
          </div>

          <market-info-buttons
            :is-loading="isLoading"
            @dismiss="dismissChanges"
            @close="closeModal"
          />
        </default-form>
      </validation-observer>
    </div>
  </modal>
</template>

<script>
import statuses from '@/constants/statuses'
import marketplaceCode from '@/constants/marketplace-codes'
import { authStatuses, editableFields } from '@/constants/shops'
import Badge from '@/components/Common/Badge.vue'
import DefaultForm from '@/components/Interface/Form.vue'
import Checkbox from '@/components/Interface/Checkbox.vue'
import CloseButton from '@/components/Interface/CloseButton.vue'
import MarketInfoButtons from '@/components/Marketplaces/Modals/MarketInfoButtons.vue'
import RefreshButton from '@/components/Interface/RefreshButton.vue'
import Loader from '@/components/Common/Loader.vue'
import marketplaceName from '@/constants/marketplace-name'
import { mapActions } from 'vuex'

export default {
  components: {
    DefaultForm,
    Checkbox,
    Badge,
    CloseButton,
    MarketInfoButtons,
    RefreshButton,
    Loader
  },

  props: {
    isLoading: Boolean
  },

  data () {
    return {
      modalName: 'market-info',
      shop: {},
      tokenStatuses: {
        actual: 'Верный',
        wrong_token: 'Просрочен'
      },
      isRefreshProcessed: false,
      marketplaceName
    }
  },

  computed: {

    statusList () {
      return Object.values(statuses)
    },

    authStatus () {
      return this.shop.authorization_status
    },

    isAliexpressShop () {
      return this.shop.marketplace_code === marketplaceCode.ALI
    },

    requires2FA () {
      return this.authStatus === authStatuses.requires2FA
    },

    wrongPass () {
      return this.authStatus === authStatuses.wrongPassword
    },

    editableCredentials () {
      return editableFields[this.shop.marketplace_code]
    }
  },

  methods: {

    ...mapActions('marketplaces', ['getAliTokenUpdateUrl']),

    setShopInfo ({ params }) {
      this.shop = { ...params.shop, shop: params.shop.name }
    },

    dismissChanges () {
      this.closeModal()
    },

    closeModal () {
      this.$modal.hide(this.modalName)
    },

    onSubmit () {
      const credentials = {}

      if (this.shop.ozon_key) {
        credentials.ozon_key = this.shop.ozon_key
      }

      if (this.shop.wildberries_stats_token) {
        credentials.wildberries_stats_token = this.shop.wildberries_stats_token
      }

      if (this.shop.wildberries_token) {
        credentials.wildberries_token = this.shop.wildberries_token
      }

      this.$emit('save-changes', { ...this.shop, credentials })
    },

    async onRefreshToken () {
      try {
        this.isRefreshProcessed = true
        const url = await this.getAliTokenUpdateUrl({
          id: this.shop.pk,
          page_url: location.href
        })
        if (url) {
          window.location = url
        }
      } finally {
        this.isRefreshProcessed = false
      }
    },

    isWildberriesTokenInvalid (field) {
      return field.key === 'wildberries_stats_token' && !this.shop[field.key]
    }
  }
}
</script>

<style lang="stylus" scoped>
  .market-info
    padding 30px 40px
    font-size 14px

    @media (max-width 768px)
      padding 16px
      padding-top 65px

    &__header
      display flex
      align-items center
      min-height 36px

    &__heading
      font-size 20px

    &__property,
    &__status
      display grid
      grid-template-columns 40% 60%
      align-items center
      padding-top 20px

      @media (max-width 768px)
        display flex
        justify-content space-between

    &__property-name
      @media (max-width 768px)
        color rgba(#2c2c37, 0.5)

    &__icon
      width 15px
      height 15px
      margin-right 8px

      &.svg-icon--close
        width 9px
        height 9px

      &.svg-icon--ok
        color #3cc13b

      &.svg-icon--info-fill
        margin-right 0px
        margin-left 5px
        color #c4c4c4

    &__tooltip
      >>> .trigger
        display flex !important
        align-items center

    &__auth
      display flex
      align-items center
      white-space nowrap

      @media (max-width 768px)
        flex-wrap wrap
        justify-content flex-end

    &__auth-status,
    &__token-status
      display flex
      align-items center

    &__refresh-btn
      margin-left 10px

    &__logout
      margin-left 13px

    &__2fa-login
      min-height 24px

    &__property-shop
      @media (max-width 768px)
        flex-wrap wrap

      .market-info__property-value
        width 100%
        margin-top 5px

    &__checkbox
      >>>.checkbox__text
        color #2c2c37

      >>>.checkbox__in
        display flex
        align-items center

  .vm--container
    >>> .vm--modal
      overflow unset
</style>
