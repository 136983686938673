<template>
  <div class="o2a-tasks">
    <ozon-2-ali-description />
    <ozon-2-ali-shops />
    <ozon-2-ali-task-list v-if="showTaskList" />
  </div>
</template>

<script>
import Ozon2AliShops from '@/components/Ozon2Ali/Tasks/Ozon2AliShops'
import Ozon2AliDescription from '@/components/Ozon2Ali/Tasks/Ozon2AliDescription'
import Ozon2AliTaskList from '@/components/Ozon2Ali/Tasks/Ozon2AliTaskList'
import { mapState } from 'vuex'

export default {
  name: 'Ozon2AliTasks',
  components: { Ozon2AliShops, Ozon2AliDescription, Ozon2AliTaskList },
  computed: {
    ...mapState('ozon2ali', ['shops', 'isShopsLoading']),

    showTaskList () {
      return this.shops.length && !this.isShopsLoading
    }
  }
}
</script>

<style>

</style>
