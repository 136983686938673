import marketplaceCodes from './marketplace-codes'

export const columns = [{
  name: 'ID',
  sortable: true,
  key: 'id'
}, {
  name: 'Магазин',
  sortable: true,
  editable: true,
  key: 'shop',
  type: 'shop'
}, {
  name: 'Маркетплейс',
  sortable: true,
  key: 'marketplace_name',
  type: 'marketplace'
}, {
  /* name: 'Товары',
  sortable: true,
  key: 'product_count',
  type: 'split_number'
}, {
  name: 'Заказы',
  sortable: true,
  key: 'order_count',
  type: 'split_number'
}, {
  name: 'Продажи (руб.)',
  sortable: true,
  key: 'selling_amount',
  type: 'split_number'
}, { */
  name: 'Статус',
  sortable: true,
  editable: true,
  key: 'is_active',
  type: 'status'
}, {
  name: 'Авторизация в ЛК',
  sortable: true,
  editable: false,
  key: 'is_enable_ali_access_data',
  type: 'auth',
  headStyle: { display: 'block', maxWidth: '130px' }
}, {
  name: 'Сбор данных',
  sortable: false,
  editable: false,
  key: 'sync_daily_report',
  type: 'sync'
}]

export const authStatuses = Object.freeze({
  success: 'success',
  unknown: 'unknown',
  requires2FA: 'requires_2fa',
  wrongPassword: 'wrong_password'
})

export const wildberriesAuthStatuses = Object.freeze({
  ACTIVE: { key: 'ACTIVE', name: 'Активный', icon: 'ok' },
  INACTIVE: { key: 'INACTIVE', name: 'Просрочен', icon: 'alert-triangle' },
  MISSING: { key: 'MISSING', name: 'Отсутствует', icon: 'close' }
})

export const ozonAuthStatuses = Object.freeze({
  ACTIVE: { key: 'ACTIVE', name: 'Активный', icon: 'ok' },
  INACTIVE: { key: 'INACTIVE', name: 'Просрочен', icon: 'alert-triangle' },
  MISSING: { key: 'MISSING', name: 'Отсутствует', icon: 'close' }
})

export const editableFields = Object.freeze({
  [marketplaceCodes.OZON]: [{
    name: 'Ozon API key Администратора',
    key: 'ozon_key',
    rules: 'required|apiOzonKey'
  }],
  [marketplaceCodes.ALI]: [],
  [marketplaceCodes.WILDBERRIES]: [{
    name: 'Токен "Маркетплейс", "Контент" и "Аналитика"',
    key: 'wildberries_token',
    rules: 'required|apiWbKey' // /apiWbKey
  }, {
    name: 'Токен "Статистика", "Вопросы и отзывы", "Цены и скидки"',
    key: 'wildberries_stats_token',
    rules: 'required|apiWbKey64' // |apiWbKey64
  }, {
    name: 'Токен "Продвижение"',
    key: 'wildberries_adv_token',
    rules: 'required|apiWbKeyAdv' // |apiWbKeyAdv
  }],
  [marketplaceCodes.YANDEX]: [{
    name: 'ID приложения в Яндекс.Маркет',
    key: 'beru_app_id',
    rules: 'required|beruAppId'
  }, {
    name: 'Токен приложения в Яндекс.Маркет',
    key: 'beru_token',
    rules: 'required|beruToken'
  }, {
    name: 'Авторизационный токен на Яндекс.Маркет',
    key: 'yandex_key',
    rules: 'required|yandexKey'
  }],
  [marketplaceCodes.SBER]: []
})
